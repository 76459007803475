'use client';
import { motion } from "framer-motion";
import styles from "../styles";
import { fadeIn, staggerContainer } from '../utils/motion';
import { theBrand } from "../constants";
import TextBoxCustom from "../components/TextBoxCustom";



const About = () => (
      <section id="about" className={` relative z-10`}> 
    
      <motion.div variants={staggerContainer} initial='hidden' whileInView='show' 
        className={`${styles.innerWidth} ${styles.paddings} bg-neutral-950 pt-8 mt-0 mx-auto flex lg:flex-row flex-col gap-8 `}
        viewport={{ once : true, amount: 0.25 }}
  
      >
        <div className="flex-[0.6] ">
        <h2 className=" justify-start brand-font text-gray-200 text-[35px]">THE<br/> BRAND </h2>
      
        <motion.div
          variants={fadeIn('left', 'tween' , 0.2, 1)}
          className={`flex-1 flex py-6 pl-0 flex-col ${styles.flexStart}`}
        >
          <div className="h-[550px]   ">
            <picture  className="w-[100%] h-[100%] object-cover  rounded-[0px]"  >
              <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaToppingBuns.webp"  className="w-[100%] h-[100%] object-cover  rounded-[0px]"   /> 
              <source type="image/jpg" srcSet="/assets/images/PappaRotiKenyaToppingBuns.jpg"  className="w-[100%] h-[100%]object-cover  rounded-[0px]" /> 
              <img src="/assets/images/PappaRotiKenyaToppingBuns.jpg" alt="PappaRotiUgandaToppingBuns" className="w-[100%] h-[100%] object-cover  rounded-[0px]"  /> 
          </picture>
          </div>
          
         </motion.div>
        </div>
        
        <motion.div
          variants={fadeIn('left', 'tween' , 0.2, 1)}
          className="flex-[0.4] flex justify-center flex-col"
          viewport={{once: true, amount: 0.25}}
        >
          <div className=" flex justify-center">
          <div className="flex flex-row ">
            <h4 className="bold text-gray-200 mr-2">MALAYSIA</h4>
            <hr style={{width: 180, height: 2, alignSelf: 'center', borderColor:'#eee', color: '#eee', backgroundColor: '#eee'}}/>
            <h4 className="bold text-gray-200 ml-2">2003</h4>
          </div>
          </div>
          
          <div className="mt-[48px] flex flex-wrap justify-between gap-[24px]">
            {theBrand.map((feature) => (
              <TextBoxCustom 
                key={feature.title}
                {...feature}
              />
            ))}
          </div>
  
          <h3 className="bold py-6 text-[24px] text-gray-200">PappaRoti is a global chain of cafes specializing in a unique and distinctive coffee-caramel-coated bun</h3>
        <p className="text-[14px] pt-4 text-gray-200" >Originating in Malaysia in 2003, PappaRoti has since expanded to various countries, becoming a household name. The turning point came in 2009 when Ms. Rasha Al Danhani introduced PappaRoti in the UAE. The brand went from being a small bakery in Malaysia to a full-service cafe in the UAE, with the inception of the first kiosk at The Dubai Mall. The brand witnessed tremendous success and has not looked back, thanks to the unique and authentic taste of the buns. The unique one- product snack concept has a global appeal, the crispy coffee-caramel exterior and fluffy buttery center has managed to capture passersby by just its aroma. The popularity of the brand has captivated the audience with over 400 outlets all over the globe.</p>
          <a href="https://papparoti.ae/" target="_blank" rel="noreferrer" >
            <div className=" my-6 border w-[120px] border-white">
              <p className=" p-2 text-[12px] text-gray-200 text-center semi-bold">LEARN MORE</p>
            </div>
          </a>    
        </motion.div>
      </motion.div>
    </section>
);

export default About;
