'use client';
import { motion } from "framer-motion";
import styles from "../styles";
import { fadeIn, fadeInSimple, staggerContainer } from '../utils/motion';



const OurMenu = () => (
      <section id="our-menu" className={` mt-16 pt-16 relative z-10`}> 
    
      <motion.div variants={staggerContainer} initial='hidden' whileInView='show' 
        className={`${styles.innerWidth} ${styles.paddings} bg-[#ffdcca] pt-12 mx-auto flex flex-1 lg:flex-row flex-col gap-8 `}
        viewport={{ once : true, amount: 0.25 }}
  
      >
        <div className="flex-[0.3] ">
        <h2 className=" justify-start brand-font text-[35px]">OUR<br/> MENU </h2>
       <motion.div
          variants={fadeIn('right', 'tween' , 0.2, 1)}
          className={`flex flex-col`}
        >
          <h3 className="bold py-6 max-w-lg text-[24px]">Welcome to PappaRoti cafe, where every bite and sip is a signature experience, carefully crafted to delight your senses. Our menu is a symphony of flavors, dedicated to celebrating the art of buns, coffee, teas, and cold beverages.</h3>
        {/* <div className="flex flex-row justify-between">

          <a href="https://files.papparoti.ke/KENYA-FOOD-MENU.pdf" download='KENYA-FOOD-MENU.pdf' target='_blank' rel="noreferrer">
         <button >
            <div className=" justify-self-start my-6 border w-[140px] border-black  justify-evenly flex flex-row">
            <img src="/assets/icons/download.svg" className="w-[24px]" alt="" />
              <p className=" py-2 px-1 text-[12px] semi-bold">FOOD MENU</p>
            </div>
          </button>
          </a>
          <a href="https://files.papparoti.ke/PappaRoti-Broadwalk-Menu.pdf" download='PappaRoti-Broadwalk-Menu.pdf' target='_blank' rel="noreferrer">
         <button >
            <div className=" justify-self-start my-6 border w-[140px] border-black  justify-evenly flex flex-row">
            <img src="/assets/icons/download.svg" className="w-[24px]" alt="" />
              <p className=" py-2 px-1 text-[12px] semi-bold">COFFEE & BUN</p>
            </div>
          </button>
          </a>
        </div> */}
           </motion.div>
        </div>

        <motion.div variants={fadeInSimple('tween', 0.2, 1)} className="flex flex-[0.4]">
          <div className="flex flex-row justify-between">
            <img src="/assets/bun/bunBaskets.png" alt="" className="max-h-[340px]" />
            <div className="">
 </div>
          </div>
        </motion.div>
        
        <motion.div
          variants={fadeIn('left', 'tween' , 0.2, 1)}
          className="flex-[0.3] flex justify-center flex-col"
          viewport={{once: true, amount: 0.25}}
        >
          <h3 className="bold">PAPPAROTI BUN</h3>
          <p className="text-[12px] light pb-4 ">Our buns are more than just a snack; they are a work of culinary art. Each bite is an explosion of taste and texture, a blend of tradition and innovation that will transport you to a world of gastronomic pleasure. From savory to sweet, our signature buns are the heart and soul of our menu, made with the finest ingredients and a dash of creativity.</p>
           
          
          <h3 className="bold">COFFEE BEVERAGES</h3>
          <p className="text-[12px] light  pb-4 ">Elevate your caffeine fix to a new level of sophistication with our signature coffee beverages. Our skilled baristas work their magic to create the perfect balance of aroma, flavor, and presentation. From velvety lattes to robust espressos, each sip tells a story of passion and expertise, giving you a coffee experience like no other.</p>
           
          
          <h3 className="bold">SIGNATURE TEA</h3>
          <p className="text-[12px] light  pb-4 ">Discover the serenity of a perfect tea ceremony with our exquisite selection of signature teas. Sourced from the world's finest tea gardens, our teas are carefully brewed to release their true essence. Whether you prefer the comforting warmth of a classic chai or the refreshing zest of herbal infusions, our signature teas offer a delightful journey for your taste buds.</p>
           
          
        </motion.div>
  
        
  
  
      </motion.div>
    </section>
);

export default OurMenu;
