import styles from "../styles";


const TextBoxCustom = ({ imgUrl, title, subtitle, subtitle2 }) => (
  <div className="flex-1 flex flex-col sm:max-w-[250px] min-w-[210px]">
   {/* <div className={`${styles.flexCenter} w-[70px] h-[70px] rounded-[24px]`}>
  <img src={imgUrl} alt="icon" className="w-1/2 h-1/2 object-contain"  />
   </div> */}
   <div className={`${styles.flexCenter} flex flex-col`}>
   <h2 className=" justify-start text-gray-100 brand-font text-[35px]">{title} </h2>
   <p className="italic-light text-gray-100 py-1" >{subtitle}</p>
   <p className="bold text-gray-100" >{subtitle2}</p>
   </div>
    {/* <h1 className="mt-[26px] font-bold text-[24px] leading-[30px] text-darker ">{title}</h1> */}
    {/* <p className="flex-1 mt-[16px] font-normal text-[18px] text-darker leading-[32px] ">{subtitle}</p> */}
  </div>
);

export default TextBoxCustom;
