import "./App.css";
import { Header, Footer } from "./components";
import Loading from "./loaders/Loading";
import { About, Menu, Hero, WhatsNew, OurMenu, Countries } from "./sections";
import { Suspense, useEffect, useState } from "react";
import { lazy } from "react";

const LazyHeader = lazy(() => import("./components/Header"));
const LazyMenu = lazy(() => import("./sections/Menu"));
const LazyHero = lazy(() => import("./sections/Hero"));
const LazyWhatsNew = lazy(() => import("./sections/WhatsNew"));
const LazyOurMenu = lazy(() => import("./sections/OurMenu"));
const LazyCountries = lazy(() => import("./sections/Countries"));
const LazyAbout = lazy(() => import("./sections/About"));
const LazyFooter = lazy(() => import("./components/Footer"));

function App() {
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 11000);
  // }, []);
  // if (loading) {
  //   return <Loading />;
  // }

  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const handleVideoLoaded = () => {
      setVideoLoaded(true);
    };

    window.addEventListener("videoLoaded", handleVideoLoaded);

    return () => {
      window.removeEventListener("videoLoaded", handleVideoLoaded);
    };
  }, []);

  return (
    <div className="bg-[#c9d7b5] overflow-hidden ">
      {/* {videoLoaded ? ( */}
      <Suspense fallback={<Loading />}>
        <div className=" relative">
          <Header />
        </div>
        <Hero />
        <LazyAbout />
        <LazyMenu />
        <LazyOurMenu />
        {/* <GiftVouchers /> */}
        <LazyCountries />
        <LazyWhatsNew />
        <LazyFooter />
      </Suspense>
      {/* ) : (
        <Loading />
      )} */}
    </div>
  );
}

export default App;
