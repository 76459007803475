'use client';
import { motion } from "framer-motion";
import styles from "../styles";
import { staggerContainer } from '../utils/motion';



const Countries = () => (
      <section id="countries" className={` ${styles.paddings} relative z-10`}> 
    
      <motion.div variants={staggerContainer} initial='hidden' whileInView='show' 
        className={`${styles.innerWidth} pt-12 mx-auto flex  flex-col gap-8 `}
        viewport={{ once : true, amount: 0.25 }}
      >
        <div className="flex-1 flex lg:flex-row flex-col align-top ">
          <div className="flex-[0.5] sm:mb-2 mb-4">
            <h2 className=" justify-start brand-font text-[35px]">ONE BUN<br/> 70+ COUNTRIES </h2>
            <h4 className=" text-[20px] my-4 mt-12 ">
            The star of PappaRoti is the bun, it has a huge fan following that goes beyond celebrities and royalties. Haven’t you tried the famous PappaRoti bun yet? If you have, you would have every reason to franchise with us.
            </h4>

          </div>
          <div className="flex-[0.5] ">
        <div className=" max-h-[700px]">
          <img src="/assets/images/worldmap.png" className=" object-cover" alt="" />
        </div> 
          </div>
     
        </div>

  
  
      </motion.div>
    </section>
);

export default Countries;
