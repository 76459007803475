export const exploreMenuItems = [
  {
    id: "menuItem-1",
    imgUrl: "/assets/papparotiafricamilkshakes.jpg",
    imgWebPUrl: "/assets/papparotiafricamilkshakes.webp",
    imgAlt: "papparotiafricamilkshakes",
    title: "Cold",
    icon: "/assets/iced-coffee.svg",
    iconAlt: "iced-coffee",
  },
  {
    id: "menuItem-2",
    imgUrl: "/assets/papparotibestcoffeenairobi.jpg",
    imgWebPUrl: "/assets/papparotibestcoffeenairobi.webp",
    imgAlt: "papparotibestcoffeenairobi",
    title: "Hot",
    icon: "/assets/coffee-love.svg",
    iconAlt: "coffee-cup",
  },

  {
    id: "menuItem-3",
    imgUrl: "/assets/papparotibestcoffeebuns.jpg",
    imgWebPUrl: "/assets/papparotibestcoffeebuns.webp",
    imgAlt: "papparotibestcoffeebuns",
    title: "Bun",
    icon: "/assets/bun-icon.svg",
    iconAlt: "bun-icon",
  },
  {
    id: "menuItem-4",
    imgUrl: "/assets/papparotikenyacoffeebunsjuice.jpg",
    imgWebPUrl: "/assets/papparotikenyacoffeebunsjuice.webp",
    imgAlt: "papparotikenyacoffeebunsjuice",
    title: "Meals",
    icon: "/assets/foods.svg",
    iconAlt: "foods",
  },
  {
    id: "menuItem-5",
    imgUrl: "/assets/papparotibestjuicesnairobi.jpg",
    imgWebPUrl: "/assets/papparotibestjuicesnairobi.webp",
    imgAlt: "papparotibestjuicesnairobi",
    title: "Juices",
    icon: "/assets/juicetakeaway.svg",
    iconAlt: "juice",
  },
];

export const startingFeatures = [
  "Browse our Menu to find what tickles your tastebuds",
  "Call us to Make an order directly from us",
  "Find us on your favorite Food Delivery App",
];

export const newFeatures = [
  {
    imgUrl: "/assets/kenya.svg",
    title: "Opening Soon",
    subtitle:
      "We are working on bringing delicious treats and drinks nearer you. Watch this space!",
  },
  {
    imgUrl: "/assets/africa.svg",
    title: "4 New Countries",
    subtitle:
      "This year the Pappa Roti brand soars it's wings to four new countries in just 6 months ",
  },
];
export const theBrand = [
  {
    imgUrl: "/assets/kenya.svg",
    title: "24",
    subtitle: "International",
    subtitle2: "AWARDS",
  },

  {
    imgUrl: "/assets/africa.svg",
    title: "410+",
    subtitle: "Outlets ",
    subtitle2: "GLOBALLY",
  },
];

export const locations = [
  {
    imgUrl: "/assets/papparotibroadwalkparklands.jpg",
    imgWebPUrl: "/assets/papparotibroadwalkparklands.webp",
    imgAlt: "broadwalkparklandsnairobi",
    title: "Broadwalk Mall, Parklands",
    subtitle:
      "You can find our flagship Pappa Roti in Parklands opposite Parklands Sports Club. Conveniently located near Westlands",
  },
  {
    imgUrl: "/assets/papparotinairobicoffee.jpg",
    imgWebPUrl: "/assets/papparotinairobicoffee.webp",
    imgAlt: "kilimanipapparotibranch",
    title: "Circle Mall, Kilimani",
    subtitle:
      "Our next location is under construction and will serve people from Kilimani, Lavington and CBD ",
  },
  // {
  //   imgUrl: '/coffehouse.jpg',
  //   title: 'With one platform you can explore the whole world virtually',
  //   subtitle:
  //       'Quam quisque id diam vel quam elementum. Viverra nam libero justo laoreet sit amet cursus sit. Mauris in aliquam sem',
  // },
];

export const socials = [
  {
    name: "boltfood",
    url: "/assets/Bolt_Food.svg",
  },
  {
    name: "glovo",
    url: "/assets/Glovo.svg",
  },
  {
    name: "ubereats",
    url: "/assets/uber-eats.svg",
  },
  {
    name: "deliveroo",
    url: "/assets/deliveroo.svg",
  },
  {
    name: "doordash",
    url: "/assets/doordash.svg",
  },
];
