
import { motion } from "framer-motion";
import { staggerContainer } from '../utils/motion';



const Hero = () => (
  <section id="home"  className={` relative z-10`}>
    <motion.div
      variants={staggerContainer}
      initial='hidden'
      whileInView='show'
      viewport={{ once: true, amount: 0.25 }}
      className={`w-full mx-auto flex flex-col bg-black/[0.3] bg-neutral-950`}
    >
      <div className='relative w-full md:-mt-[20px] -mt-[12px]'>
        {/* <div className="absolute hero-image w-full h-[700px]"/> */}
        {/* <div className="w-full h-[700px] absolute bg-black/[0.3]"> */}
        
        <div className="video-container">
      <video autoPlay playsInline muted loop>
      <source src="/assets/videos/PR_Video_Muted.mp4" type="video/mp4" />
      
      Your browser does not support the video tag.
      </video>
      <div className=" image-overlay">
            {/* <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaBuns.webp" /> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaBuns.jpg" /> 
            <img src="/assets/images/PappaRotiKenyaBuns.jpg" alt="PappaRotiUgandaBuns" />  */}
        </div>
    </div>
    {/* </div> */}

           {/* <picture className="w-full h-[700px]  object-cover z-1 relative">
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaBuns.webp" className="w-full  h-[700px] sm:h-[700px] object-cover z-10 relative "/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaBuns.jpg" className="w-full  h-[700px] sm:h-[700px] object-cover z-10 relative "/> 
            <img src="/assets/images/PappaRotiKenyaBuns.jpg" alt="PappaRotiUgandaBuns" className="w-full  h-[700px] sm:h-[700px] object-cover z-10 relative "/> 
        </picture>
        <video autoPlay loop muted className="w-full h-[700px]  object-cover relative">
          <source src="/assets/videos/PR_Video.mp4" type="video/mp4" />
          Your browser does not support the video
        </video> */}
        {/* <picture className="w-full h-[700px]  object-cover z-10 relative">
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaBuns.webp" className="w-full  h-[700px] sm:h-[700px] object-cover z-10 relative "/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaBuns.jpg" className="w-full  h-[700px] sm:h-[700px] object-cover z-10 relative "/> 
            <img src="/assets/images/PappaRotiKenyaBuns.jpg" alt="PappaRotiUgandaBuns" className="w-full  h-[700px] sm:h-[700px] object-cover z-10 relative "/> 
        </picture> */}
        <div className="w-full h-[52px] align-middle opacity-70 flex justify-center sm:-mt-[70px] -mt-[50px]  relative z-10">
          <a href="#about">
          <img src="/assets/icons/chevron-down.svg" className="h-[52px] pb-2"  alt="" />
          </a>
            </div>
      </div>
    </motion.div>
  </section>
);

export default Hero;
