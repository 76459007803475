import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import { Autoplay, Navigation } from 'swiper/modules';



const Menu = () => {
  return (
    <section className={` mt-12 pt-12 z-10`} 
      id='menu'
    >
     <Swiper
     className="swiper-color"
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          480:{
            slidesPerView:2,
          },
     
          865:{
            slidesPerView:3
          },
       
          1500:{
            slidesPerView:4
          },
        }}
        navigation
        modules={[Autoplay, Navigation]}
      >
      
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaSignatureBuns.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaSignatureBuns.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaSignatureBuns.jpg" alt="PappaRotiKenyaSignatureBuns"  className="" /> 
          </picture>
        </SwiperSlide>
        
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaSignatureTea.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaSignatureTea.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaSignatureTea.jpg" alt="PappaRotiKenyaSignatureTea"  className="" /> 
          </picture>
        </SwiperSlide>
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaColdMilkshake.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaColdMilkshake.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaColdMilkshake.jpg" alt="PappaRotiKenyaColdMilkshake"  className="" /> 
          </picture>
        </SwiperSlide>
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaFreshJuices.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaFreshJuices.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaFreshJuices.jpg" alt="PappaRotiKenyaFreshJuices"  className="" /> 
          </picture>
        </SwiperSlide>
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaKonafa.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaKonafa.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaKonafa.jpg" alt="PappaRotiKenyaKonafa"  className="" /> 
          </picture>
        </SwiperSlide>
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaToppingBuns.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaToppingBuns.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaToppingBuns.jpg" alt="PappaRotiKenyaToppingBuns"  className="" /> 
          </picture>
        </SwiperSlide>
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaColdJuices.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaColdJuices.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaColdJuices.jpg" alt="PappaRotiKenyaColdJuices"  className="" /> 
          </picture>
        </SwiperSlide>
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaIceCream.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaIceCream.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaIceCream.jpg" alt="PappaRotiKenyaIceCream"  className="" /> 
          </picture>
        </SwiperSlide>
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaPlainBun.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaPlainBun.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaPlainBun.jpg" alt="PappaRotiKenyaPlainBun"  className="" /> 
          </picture>
        </SwiperSlide>
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaIceCreamBun.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaIceCreamBun.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaIceCreamBun.jpg" alt="PappaRotiKenyaIceCreamBun"  className="" /> 
          </picture>
        </SwiperSlide>
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaArabicCoffee.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaArabicCoffee.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaArabicCoffee.jpg" alt="PappaRotiKenyaArabicCoffee"  className="" /> 
          </picture>
        </SwiperSlide>
        <SwiperSlide>  
          <picture  className="" >
            <source type="image/webp" srcSet="/assets/images/PappaRotiKenyaHotDrinks.webp" className=""/> 
            <source type="image/jpeg" srcSet="/assets/images/PappaRotiKenyaHotDrinks.jpg"  className="" /> 
            <img src="/assets/images/PappaRotiKenyaHotDrinks.jpg" alt="PappaRotiKenyaHotDrinks"  className="" /> 
          </picture>
        </SwiperSlide>
       
      </Swiper>
    </section>
  )
}

export default Menu;
