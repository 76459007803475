'use client';

// import {  NewFeatures } from "../components";


const WhatsNew = () => (
  <section id="whats-new" className={`relative z-10`}> 
    <div
  className=" bg-[url('../public/assets/images/PappaRotiKenyaBuns.webp')] overflow-hidden  bg-cover bg-no-repeat"
  style={{  height: '800px'}}>
  <div
    className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
    >
    <div className="flex h-full items-center justify-center ">
      <div className=" bg-white h-[340px]  bg-opacity-80 align-middle rounded-t-2xl w-full mx-10">
      <div className="absolute bottom-[40%] left-20  right-20  flex lg:flex-row flex-col align-middle ">
          <div className="flex-[0.5] mb-4">
            <h2 className=" justify-start justify-items-start text-left brand-font text-[34px]">GET UPDATES &<br/>STAY CONNECTED<br />WHAT'S NEW</h2>

          </div>
          <div className="flex-[0.5]   ">
            <div className=" py-8">

            <div className="border-b-2  mb-4 flex flex-row justify-between border-black">
           <form action="" className="flex justify-between flex-1">

           <input type="text" style={{ background: 'transparent', border: 'none', outline: 'none', color: 'black'}}  placeholder="YOUR EMAIL" className="bold text-[14px]"/>

              {/* <input type="submit" value="JOIN" className="bold text-[14px]"   /> */}
              <a href="mailto:papparotike@gmail.com" placeholder="JOIN" className="text-[14px]">

                <h4 className="bold text-[14px] ">
                  JOIN
                </h4>
              </a>
            </form>

            </div>
            <a href="https://www.instagram.com/papparoti_uganda/" target="_blank" rel="noreferrer">

            <img className="h-6 mt-4" src="/assets/icons/igblack.png" alt="instagram-papparoti-uganda" />
            </a>

            </div>
          </div>
     
        </div>
      </div>
    </div>
  </div>
  </div>
   
  </section>
);

export default WhatsNew;
